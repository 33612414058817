import { ForgotPassword } from '../../containers/WebApp/ForgotPassword/ForgotPassword';

export default ForgotPassword

export const query = graphql`
query ForgotPasswordQuery($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;