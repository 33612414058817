/* global chrome */
// 
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Link, useTranslation, I18nextContext, useI18next } from 'gatsby-plugin-react-i18next';

import * as yup from 'yup';
import { AllAnimationsCombined } from '../../../components/Animation/Animation';
import { Button } from '../../../components/Button/Button';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../../components/Footer';
import Images from '../../../images';
// import { I18nextContext, Link, useI18next } from 'gatsby-plugin-react-i18next';

import { useApiBase } from '../../../services/api';
import { getAndRedirectUser, getAuth0Errors, saveAndSendAuthDataToExtension, useAuth0 } from '../../../services/auth0/useAuth0';
import { trackEvent } from '../../../services/helpers';
import '../../../styles/style.scss';
import { Error, SingleField } from '../../Landing/Contact';
import { StyledLogo } from '../Onboarding/styled';
import { SocialMedia } from '../SocialMedia/SocialMedia';
import { Footer, ForgotPasswordLink, HeaderTitle } from '../Auth/commons';
import { StyledSpan } from '../Auth/styled';
import flagPl from 'svg-country-flags/svg/pl.svg';
import flagUs from 'svg-country-flags/svg/us.svg';
import { chromeSendMessage } from '../../../services/chrome';
import { LanguageDropdown } from '../Auth/Auth';
import { ThankYou } from '../../Landing/ThankYou/ThankYou';


export const ForgotPassword = (props) => {
  const { type } = props
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const [displayThankYou, setDisplayThankYou] = useState(false)

  const api = useApiBase()
  const { initializedWebAuth0 } = useAuth0()
  const [isLoading, setIsLoading] = useState(false)
  const [mainError, setMainError] = useState(null)

  const schema = yup.object({
    email: yup
      .string(t('auth.email.enterEmail'))
      .email(t('auth.email.invalidEmail'))
      .required(t('auth.email.enterEmail')),
  });


  const FIELDS = [
    {
      name: 'email',
      label: t('auth.email'),
      placeholder: t('auth.email.placeholder'),
      inputType: 'input',
      value: '',
      fieldHint: null
    },
  ]

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setIsLoading(true)
      // initializedWebAuth0.authorize()
      initializedWebAuth0.changePassword({
        connection: 'Username-Password-Authentication',
        email: values.email
      }, callbackFunction)
    },
  });

  const callbackFunction = (e, data) => {
    setIsLoading(false)
    setDisplayThankYou(true)
  }

  if (displayThankYou) {
    return (
      <ThankYou
        displayCustomCopy
        customHeader={t("forgotPassword.checkYourEmail")}
        buttonCtaCustomCopy={t("forgotPassword.goToLoginPage")}
        customUrl="/app/login"
      />
    )
  }

  return (
    <div>
      <AllAnimationsCombined singlePage />
      <div className="container" style={{ height: '100vh' }}>
        <div className="columns">
          <div className="column is-justify-content-center is-flex" style={{ marginTop: 80, marginBottom: 80, alignItems: 'center', position: 'relative' }}>
            <div>
              <StyledLogo
                src={Images.logoYellowWhite}
                srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
              />
            </div>
            <div style={{ marginTop: -10, position: 'absolute', left: '50%', marginLeft: 140 }}>
              <LanguageDropdown />
            </div>
          </div>
        </div>

        <div className="columns is-flex is-justify-content-center mt-0">
          <div className="column is-flex is-justify-content-flex-start is-flex-direction-column	" style={{ maxWidth: 400 }}>
            <p style={{ color: '#1E222D', fontSize: 21, fontWeight: 'bold', marginBottom: 32 }}>
              {t('forgotPassword.title')}
            </p>

            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
              {FIELDS.map(item => {
                return (
                  <SingleField
                    key={item.name}
                    isErrorVisible={formik.touched[item.name] && formik.errors[item.name]}
                    fieldError={formik.touched[item.name] && formik.errors[item.name] && formik.errors[item.name]}
                    fieldHint={item.fieldHint}
                    inputProps={{
                      onBlur: formik.handleBlur,
                      name: item.name,
                      label: item.label,
                      placeholder: item.placeholder,
                      inputType: item.inputType,
                      handleChangeField: formik.handleChange,
                      value: formik.values[item.name]
                    }}
                  />
                )
              })}

              <Button disabled={!formik.isValid} className={`${isLoading && 'is-loading'} `} style={{ marginTop: 8, width: '100%' }} type="submit">
                {t('auth.resetPassword')}
              </Button>

            </form>
            <Footer type={"resetPassword"} />
          </div>
        </div>
      </div>
    </div >
  )
}
